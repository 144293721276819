import PriceSettingPopup from "components/molecules/popup/PriceSettingPopup";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const SubscriptionPriceSettingPopup = ({ isOpen, setIsOpen }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();

  const changePrice = async (netAmount: number) => {
    if (netAmount === user.subscriptionPrice?.net) {
      setIsOpen(false);
      return;
    }

    const response = await maloumClient.users.updateProfile({
      subscriptionNetAmount: netAmount,
    });
    if (!(response instanceof Error)) {
      useUserStore.setState({ user: response });
      setIsOpen(false);
    } else {
      throw Error;
    }
  };

  return (
    <PriceSettingPopup
      heading={t("subscription.price.monthly")!}
      priceChangeButtonText={t("subscription.price.save")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onPriceChange={changePrice}
      netAmount={user.subscriptionPrice?.net}
    />
  );
};

export default SubscriptionPriceSettingPopup;
