import SelectGenderPage from "pages/gender/SelectGender";
import { useState } from "react";
import BecomeCreatorPage from "./BecomeCreator";
import SetBirthdayPage from "./SetBirthday";

const Intro = () => {
  const [step, setStep] = useState(0);

  return (
    <div className={"full-height flex"}>
      {step === 0 && <SetBirthdayPage />}
      {step === 1 && <BecomeCreatorPage onBack={() => setStep(0)} onNext={() => setStep(2)} />}
      {step === 2 && <SelectGenderPage onBack={() => setStep(1)} onNext={() => {}} />}
    </div>
  );
};

export default Intro;
