import { MediaUploadStatus, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { ProfileImageWithButton } from "components/basics/ProfileImage";
import useChat from "hooks/useChat.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { ReactNode, useCallback } from "react";
import useUserStore from "state/userState";
import VerifiedUserIcon from "../icons/VerifiedUserIcon";

type Props = {
  userId: string;
  username: string;
  userProfilePictureUrl?: string;
  userProfilePictureUploadStatus: MediaUploadStatus;
  isUserVerified: boolean;
  additionalInfo: ReactNode;
  actions: ReactNode;
  className?: string;
  "data-testid"?: string;
};

const FanItem = ({
  userId,
  username,
  userProfilePictureUrl,
  userProfilePictureUploadStatus,
  isUserVerified,
  additionalInfo,
  actions,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { openChat } = useChat();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleClick = useCallback(async () => {
    if (user._id === userId) navigate(`/creator/${username}`);
    else if (!isUserVerified) await openChat(userId);
    else navigate(`/creator/${username}`);
  }, [username, isUserVerified]);

  return (
    <article className={classNames("flex", className)} data-testid={dataTestId}>
      <ProfileImageWithButton
        className={"h-10 w-10"}
        url={userProfilePictureUrl}
        uploadStatus={userProfilePictureUploadStatus}
        onClick={handleClick}
      />
      <div className="ml-3 flex flex-1 flex-col justify-center">
        <h3 className="flex items-center text-base">
          <button onClick={handleClick} className="mr-1">
            {username}
          </button>
          {isUserVerified && <VerifiedUserIcon />}
        </h3>
        {additionalInfo}
      </div>
      <div>{actions}</div>
    </article>
  );
};
export default FanItem;
