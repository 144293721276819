import { ChatDto, MessageContentType, MessageDto } from "@neolime-gmbh/api-gateway-client";
import useMessages from "hooks/useMessages.hook";
import useSendMessage from "hooks/useSendMessage.hook";
import { useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import LoadingChat from "./LoadingChat";
import Message from "./Message";

type Props = { chatId: string; chat: ChatDto };

const ChatList = ({ chatId, chat }: Props) => {
  const { messages, isLoading, isFetching, hasMore, fetchMore } = useMessages(chatId);
  const { deleteMessage } = useSendMessage(chatId);
  const [firstLoad, setFirstLoad] = useState(true);
  const [lastHeight, setLastHeight] = useState(0);
  const [lastMessage, setLastMessage] = useState<MessageDto | undefined>();

  const refBottom = useRef<HTMLDivElement | null>(null);
  const { ref: refTop, inView } = useInView();

  const toBottom = () => refBottom.current?.scrollIntoView && refBottom.current.scrollIntoView();

  useEffect(() => {
    if (firstLoad && messages.length > 0) {
      toBottom();
      setFirstLoad(false);
    }
    if (messages.length > 0) {
      if (messages[0]._id !== lastMessage?._id) toBottom();
      setLastMessage(messages[0]);
    }
  }, [messages]);

  useEffect(() => {
    if (inView && hasMore && !isFetching && !isLoading && !firstLoad) fetchMore();
  }, [inView]);

  useEffect(() => {
    if (messages.length === 0) return;
    if (lastHeight !== 0) {
      window.scrollTo({ top: Math.max(window.pageYOffset, 0) + document.body.scrollHeight - lastHeight });
    }
    setLastHeight(document.body.scrollHeight);
  }, [messages]);

  if (isLoading) return <LoadingChat showHeader={false} />;

  return (
    <div className="relative grow">
      <div ref={refTop} className="absolute left-0 top-[3rem]" />
      <div className="flex flex-col-reverse pt-8">
        {messages.map((m, i) => {
          let textMessage;
          if ((m.content.type === MessageContentType.CHAT_PRODUCT || m.content.type === "media") && m.content.text) {
            const mCopy = { ...m, content: { ...m.content, type: MessageContentType.TEXT } };
            textMessage = (
              <Message
                message={mCopy}
                chatPartnerId={chat.chatPartner._id}
                key={i}
                displayLinks={chat.chatPartner.isTrusted}
                onDelete={() => {
                  deleteMessage(m._id, true);
                }}
                onDeleteBroadcasted={() => {
                  deleteMessage(m._id, true, true);
                }}
              />
            );
          }
          return (
            <>
              {textMessage && textMessage}
              <Message
                message={m}
                chatPartnerId={chat.chatPartner._id}
                key={m._id}
                displayLinks={chat.chatPartner.isTrusted}
                onDelete={() => {
                  deleteMessage(m._id, false);
                }}
                onDeleteBroadcasted={() => {
                  deleteMessage(m._id, false, true);
                }}
              />
            </>
          );
        })}
        {isFetching && (
          <div className="absolute left-1/2 top-2 mx-auto w-fit -translate-x-1/2 py-4">
            <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin fill-gray-400" />
          </div>
        )}
      </div>

      <div ref={refBottom} />
    </div>
  );
};

export default ChatList;
