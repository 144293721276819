import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import type {
  ScheduledBroadcastResponseDto,
  ScheduledBroadcastResponseDtoKeysetResponse,
} from "@neolime-gmbh/api-gateway-client";

const useScheduledBroadcasts = (loadData = true) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const removeBroadcastMessage = async (messageId: string) => {
    // TODO could be renamed to cancel instead of delete
    await maloumClient.scheduledBroadcasts.deleteScheduledBroadcast(messageId);
    queryClient.setQueryData("scheduledBroadcasts", (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((message: ScheduledBroadcastResponseDto) => message._id !== messageId),
          };
        }),
      };
    });
  };

  const getScheduledBroadcasts = async (next: any): Promise<ScheduledBroadcastResponseDtoKeysetResponse> =>
    await maloumClient.scheduledBroadcasts.getScheduledBroadcasts(next.pageParam, 30);

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "scheduledBroadcasts",
    getScheduledBroadcasts,
    {
      getPreviousPageParam: (firstPage) => firstPage.next || null,
      getNextPageParam: (lastPage) => lastPage.next || null,
      enabled: loadData,
    },
  );

  const scheduledBroadcasts = data?.pages.flatMap((page) => page.data) || [];

  return {
    scheduledBroadcasts,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeBroadcastMessage,
  };
};
export default useScheduledBroadcasts;
