import { BroadcastOverviewFilter, SentBroadcastResponseDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";

const useSentBroadcasts = (broadcastFilter: BroadcastOverviewFilter = BroadcastOverviewFilter.ALL) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const fetchSentBroadcasts = async ({ pageParam }: { pageParam?: string }) => {
    return maloumClient.broadcasts.getSentBroadcasts(pageParam, 15, broadcastFilter);
  };

  const { data, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["sent-broadcasts", broadcastFilter],
    fetchSentBroadcasts,
    {
      getNextPageParam: (lastPage) => lastPage.next || null,
    },
  );

  const revokeBroadcast = async (broadcastId: string) => {
    await maloumClient.broadcasts.revokeBroadcast(broadcastId);
    return broadcastId;
  };

  const revokeBroadcastMutation = useMutation({
    mutationFn: revokeBroadcast,
    onSuccess: (broadcastId) => {
      queryClient.invalidateQueries(["sent-broadcasts", BroadcastOverviewFilter.RECALLED]);
      queryClient.setQueryData(["sent-broadcasts", BroadcastOverviewFilter.ALL], (oldData: any) => {
        return {
          ...oldData,
          pages: oldData?.pages?.map((page: SentBroadcastResponseDtoKeysetResponse) => ({
            data: page.data.map((broadcast) =>
              broadcast._id === broadcastId ? { ...broadcast, isRevoked: true } : broadcast,
            ),
            next: page?.next,
          })),
        };
      });
      queryClient.setQueryData(["sent-broadcasts", BroadcastOverviewFilter.SENT], (oldData: any) => {
        return {
          ...oldData,
          pages: oldData?.pages?.map((page: SentBroadcastResponseDtoKeysetResponse) => ({
            data: page.data.filter((broadcast) => broadcast._id !== broadcastId),
            next: page.next,
          })),
        };
      });
    },
  });

  return {
    broadcasts: data?.pages?.flatMap((page) => page?.data) || [],
    isLoading: isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    revokeBroadcastMutation,
  };
};

export default useSentBroadcasts;
