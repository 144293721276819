import {
  ChatListDto,
  MediaUploadStatus,
  MessageContentTemplateMediaDto,
  ScheduledBroadcastResponseDto,
} from "@neolime-gmbh/api-gateway-client";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PopUp, { TOption } from "components/popup/PopUp";
import Button from "components/basics/Button";
import useScheduledBroadcasts from "hooks/useScheduledBroadcasts.hook";
import { HiOutlineUsers } from "react-icons/hi2";
import PillButton from "components/atoms/buttons/PillButton";
import BroadcastCard from "components/organisms/broadcast/BroadcastCard";
import useSelectedListsOverviewNavigation from "hooks/navigation/useSelectedListsOverviewNavigation";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useLocation } from "react-router-dom";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  broadcast: ScheduledBroadcastResponseDto;
  className?: string;
};

const ScheduledBroadcastCard = ({ broadcast, className }: Props) => {
  const { removeBroadcastMessage } = useScheduledBroadcasts(false);
  const [showPopup, setShowPopup] = useState(false);

  const { pathname } = useLocation();
  const navigate = useStatefulNavigate();

  const labels = (
    <ReceiverLabel
      recipientCount={broadcast.recipientCount}
      includeFromLists={broadcast.includeFromLists}
      excludeFromLists={broadcast.excludeFromLists}
    />
  );

  const onEdit = async () => {
    const selectedMedia = broadcast.content.media ? mapMediaContentToSelectedMedia(broadcast.content.media) : [];
    const data = {
      message: broadcast.content.text,
      digitalProductPrice: broadcast.content.price,
      scheduledAt: broadcast.scheduledAt,
    };

    navigate("/chat/new/message", {
      state: { redirectTo: `${pathname}?tab=messages`, data, selectedMedia, broadcastId: broadcast._id },
      replace: true,
    });
  };

  return (
    <>
      <BroadcastCard
        date={new Date(broadcast.scheduledAt)}
        content={broadcast.content}
        onOpenPopup={() => setShowPopup(true)}
        labels={labels}
        className={className}
      />

      {showPopup && (
        <OptionsPopup
          onClose={() => setShowPopup(false)}
          onDelete={() => removeBroadcastMessage(broadcast._id)}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default ScheduledBroadcastCard;

type OptionsPopupProps = {
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

const OptionsPopup = ({ onClose, onDelete, onEdit }: OptionsPopupProps) => {
  const { t } = useTranslation();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const popupOptions = [
    { title: t("edit"), onClick: () => handleEditClick() },
    { title: t("delete"), onClick: () => setShowConfirmationPopup(true) },
  ] satisfies TOption[];

  const handleDeleteClick = () => {
    setShowConfirmationPopup(false);
    onClose();
    onDelete();
  };

  const handleEditClick = async () => {
    onClose();
    await onEdit();
  };

  return showConfirmationPopup ? (
    <PopUp isOpen onClose={() => setShowConfirmationPopup(false)} title={t("delete") ?? ""}>
      <div className="py-4 text-center">{t("queue.sureDeleteMessage")}</div>
      <div className="flex gap-2">
        <Button variant="secondary" text={t("cancel")} onClick={() => setShowConfirmationPopup(false)} />
        <Button variant="primary" text={t("delete")} onClick={handleDeleteClick} />
      </div>
    </PopUp>
  ) : (
    <PopUp isOpen onClose={onClose} title={t("queue.modifyMessage") ?? ""} options={popupOptions} />
  );
};

type ReceiverLabelProps = {
  recipientCount: number;
  includeFromLists: ChatListDto[];
  excludeFromLists: ChatListDto[];
};

const ReceiverLabel = ({ recipientCount, includeFromLists, excludeFromLists }: ReceiverLabelProps) => {
  const { t } = useTranslation();
  const navigateToSelectedListsOverview = useSelectedListsOverviewNavigation();

  const label = t("queue.memberCount", { count: recipientCount });

  const handleGoToSelection = () => {
    navigateToSelectedListsOverview({ includeFromLists, excludeFromLists });
  };

  return (
    <PillButton onClick={handleGoToSelection} className="!w-fit">
      <HiOutlineUsers className="h-4 w-4" /> {label}
    </PillButton>
  );
};

const mapMediaContentToSelectedMedia = (media: MessageContentTemplateMediaDto[]) => {
  // TODO: could selected media be simplified?
  return media.map((mediaItem) => {
    return {
      _id: mediaItem._id,
      width: mediaItem.width,
      height: mediaItem.height,
      thumbnail: {
        width: mediaItem.width,
        height: mediaItem.height,
        url: mediaItem.thumbnailUrl,
        type: mediaItem.type,
        uploadStatus: MediaUploadStatus.FINISHED,
      },
      uploadStatus: MediaUploadStatus.FINISHED,
      type: mediaItem.type,
      url: mediaItem.url,
    } satisfies TSelectedMedia;
  });
};
