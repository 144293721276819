import {
  AutomatedMessageMediaDto,
  AutomatedMessageType,
  SaveAutomatedMessageRequestDto,
} from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAutomatedMessagesOverview from "./useAutomatedMessagesOverview.hook";

const mapToSelectedMedia = (media?: AutomatedMessageMediaDto[]) =>
  media?.map((media) => ({ ...media.media, _id: media.mediaId, thumbnail: media.thumbnail }));

const useAutomatedMessage = (type: AutomatedMessageType) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { refetch: refetchAutomatedMessagesOverview } = useAutomatedMessagesOverview();
  const queryClient = useQueryClient();

  const fetchAutomatedMessageByType = async () => {
    return maloumClient.automatedMessages.getAutomatedMessageByType(type);
  };

  const { data: automatedMessage, isLoading } = useQuery(["automated-message", type], fetchAutomatedMessageByType, {
    refetchOnWindowFocus: false,
  });

  const saveMutation = useMutation(async (message: SaveAutomatedMessageRequestDto) => {
    const response = await maloumClient.automatedMessages.saveAutomatedMessageByType(type, message);
    queryClient.setQueryData(["automated-message", type], () => response);
    refetchAutomatedMessagesOverview();
  });

  return {
    automatedMessage,
    media: automatedMessage ? mapToSelectedMedia(automatedMessage.media) : undefined,
    isLoading,
    saveAutomatedMessage: saveMutation.mutate,
  };
};

export default useAutomatedMessage;
