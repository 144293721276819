import { UserIcon } from "@heroicons/react/24/solid";
import { AutomatedMessageType } from "@neolime-gmbh/api-gateway-client";
import AutomatedMessageItem from "components/molecules/automated-messages/AutomatedMessageItem";
import useAutomatedMessagesOverview from "hooks/automated-messages/useAutomatedMessagesOverview.hook";
import { useTranslation } from "react-i18next";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";

type Props = {
  selectedType?: AutomatedMessageType;
  onTypeSelection: (type: AutomatedMessageType) => void;
} & BaseComponent;

const AutomatedMessagesList = ({ selectedType, onTypeSelection }: Props) => {
  const { t } = useTranslation();
  const { automatedMessagesOverview } = useAutomatedMessagesOverview();

  return (
    <div>
      <AutomatedMessageItem
        key={AutomatedMessageType.NEW_FOLLOWER}
        type={AutomatedMessageType.NEW_FOLLOWER}
        IconFront={({ className }) => <UserIcon className={className} />}
        messageTitle={t(`automatedMessages.${AutomatedMessageType.NEW_FOLLOWER}.title`)}
        active={automatedMessagesOverview?.newFollowerMessage.isActive}
        isSelected={selectedType === AutomatedMessageType.NEW_FOLLOWER}
        onClick={onTypeSelection}
      />
      <AutomatedMessageItem
        key={AutomatedMessageType.NEW_SUBSCRIBER}
        type={AutomatedMessageType.NEW_SUBSCRIBER}
        IconFront={({ className }) => <HiOutlineBanknotes className={className} />}
        messageTitle={t(`automatedMessages.${AutomatedMessageType.NEW_SUBSCRIBER}.title`)}
        active={automatedMessagesOverview?.newSubscriberMessage.isActive}
        isSelected={selectedType === AutomatedMessageType.NEW_SUBSCRIBER}
        onClick={onTypeSelection}
      />
    </div>
  );
};

export default AutomatedMessagesList;
