import {
  ChatProductDto,
  MediaUploadStatus,
  PictureCandidateDto,
  ThumbnailCandidateDto,
  UploadType,
  VideoCandidateDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import Button from "components/basics/Button";
import MediaPlaceholder from "components/MediaPlaceholder";
import OrderType from "enums/OrderType";
import { formatDurationInSecondsIntoHoursMinutesSeconds } from "helper/dateAndTimeHelper";
import useCurrency from "hooks/useCurrency.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import ChatGridImage from "./ChatGridImage";
import MediaMessage from "./MediaMessage";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { usePostHog } from "posthog-js/react";
import UnprocessedMediaMessage from "./UnprocessedMediaMessage";

type ChatProductMessageProps = {
  content: ChatProductDto;
  senderId: string;
  handleCheckout: () => void;
};

const ChatProductMessageSingle = ({ content, senderId, handleCheckout }: ChatProductMessageProps) => {
  const { t } = useTranslation();

  const { displayCurrency } = useCurrency();

  const user = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const style = useMemo(() => {
    const media = content?.media?.at(0) as PictureCandidateDto | VideoCandidateDto;
    const height = media?.height ?? 0;
    const width = media?.width ?? 0;
    return {
      height: `min(calc(${height / width} * 70vw), calc(${height / width} * 358px))`,
    };
  }, [content]);

  // show processing state
  if (content.media?.at(0)?.uploadStatus === MediaUploadStatus.PROCESSING)
    return <UnprocessedMediaMessage isSender={user._id === senderId} />;

  // else ChatProduct to buy
  return (
    <div
      className={classNames("relative flex min-h-[15rem] items-center justify-center", {
        "h-80 bg-red-100": content?.media?.at(0)?.type !== "picture" || isError,
      })}
    >
      {content.media?.at(0)?.type === "picture" && !isError && (
        <>
          <MediaPlaceholder isLoaded={!isLoading} style={style} className="rounded-r-[1.5rem] rounded-tl-[1.5rem]" />
          <img
            src={content.media[0].url}
            className={classNames("w-full", {
              hidden: isLoading,
              block: !isLoading,
            })}
            style={{ ...style, borderRadius: "inherit" }}
            alt=""
            onLoad={() => setIsLoading(false)}
            onError={() => setIsError(true)}
          />
          <div className="absolute left-0 top-0 z-[10] h-full w-full rounded-r-[1.5rem] rounded-tl-[1.5rem] bg-black/10" />
        </>
      )}
      <div className="absolute top-0 z-10 flex h-full w-full flex-col items-center justify-center px-6 py-10">
        <div className="flex items-center gap-2">
          <ContentTypeIcon
            type={content?.media?.at(0)?.type === "picture" && !isError ? "lock" : content?.media?.at(0)?.type}
            className={classNames(
              { "h-12 w-12 text-gray-700": content.media?.at(0)?.type !== "picture" || isError },
              { "h-24 w-24 text-white": content.media?.at(0)?.type === "picture" && !isError },
            )}
          />
          {content?.media?.at(0)?.type === "video" && (
            <span className="text-2xl">
              {formatDurationInSecondsIntoHoursMinutesSeconds((content.media?.at(0) as VideoCandidateDto)?.length)}
            </span>
          )}
        </div>
        <h2
          className={classNames(
            "mt-1 text-center text-base",
            { "text-gray-700": content.media?.at(0)?.type !== "picture" || isError },
            { "font-semibold text-white": content.media?.at(0)?.type === "picture" && !isError },
          )}
        >
          {content.media?.at(0)?.type === "picture" ? t("chatProduct.buyToViewImage") : t("chatProduct.buyToViewVideo")}
        </h2>
        <Button
          onClick={handleCheckout}
          text={`${t("chatProduct.unlockFor")} ${content.price && displayCurrency(content.price.net)}`}
          className="mt-6 shrink-0"
        />
      </div>
    </div>
  );
};

type GridMediaItemProps = {
  media: PictureCandidateDto | VideoCandidateDto;
  thumbnail: ThumbnailCandidateDto;
};

const GridMediaItem = ({ media, thumbnail }: GridMediaItemProps) => {
  // legacy media of type "picture" can have an undefined url -> not possible in multiple media chat products because they where introduced later
  // display picture
  if (media.type === UploadType.PICTURE || media.uploadStatus === MediaUploadStatus.PROCESSING)
    return <ChatGridImage url={thumbnail.url} uploadStatus={thumbnail.uploadStatus} type={UploadType.PICTURE} />;
  // display hidden video
  return (
    <div className="flex aspect-[130/172] w-full flex-col items-center justify-center gap-1 bg-red-100">
      <HiOutlineVideoCamera className="h-8 w-8" />
      <div className="text-lg font-normal">
        {formatDurationInSecondsIntoHoursMinutesSeconds((media as VideoCandidateDto).length)}
      </div>
    </div>
  );
};

type Props = {
  chatProduct: ChatProductDto;
  messageId: string;
  senderId: string;
};

const ChatProductMessage = ({ chatProduct, messageId, senderId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { displayCurrency } = useCurrency();
  const posthog = usePostHog();

  const goToCheckout = async () => {
    posthog.capture("chat_product-checkout");
    navigate({
      pathname: "/checkout",
      search: createSearchParams({
        type: OrderType.ChatProduct,
        id: chatProduct._id,
        messageId,
      }).toString(),
    });
  };

  const { thumbnailsToShow, remainingMedia } = useMemo(() => {
    let thumbnailsToShow = [] as ThumbnailCandidateDto[];
    if (chatProduct.thumbnails)
      if (chatProduct.thumbnails.length < 4) thumbnailsToShow = chatProduct.thumbnails.slice(0, 2);
      else thumbnailsToShow = chatProduct.thumbnails.slice(0, 4);
    return { thumbnailsToShow, remainingMedia: chatProduct.thumbnails.length - thumbnailsToShow.length };
  }, [chatProduct.thumbnails]);

  if (!chatProduct) return <></>;
  else if (chatProduct.isVisible)
    return <MediaMessage media={chatProduct.media} thumbnails={chatProduct.thumbnails} senderId={senderId} />;
  else if (chatProduct.media.length === 1)
    return <ChatProductMessageSingle content={chatProduct} senderId={senderId} handleCheckout={goToCheckout} />;
  else
    return (
      <div className="relative">
        <div className="grid grid-cols-2 gap-1">
          {thumbnailsToShow.map((t, i) => (
            <button className="relative h-fit w-full overflow-hidden rounded-md" onClick={goToCheckout} key={t.url}>
              <GridMediaItem media={chatProduct.media[i]} thumbnail={t} />
              {remainingMedia !== 0 && i === thumbnailsToShow.length - 1 && (
                <div
                  className={classNames(
                    "left-0 top-0 hidden h-full w-full items-center justify-center rounded-md bg-gray-950/25 text-4xl font-semibold text-white last:absolute last:flex",
                    {
                      "rounded-tr-[1.5rem]": chatProduct.thumbnails.length < 4,
                    },
                  )}
                >{`+${remainingMedia}`}</div>
              )}
            </button>
          ))}
        </div>
        <Button
          onClick={goToCheckout}
          text={`${t("chatProduct.unlockFor")} ${chatProduct.price && displayCurrency(chatProduct.price.net)}`}
          className="mt-1 shrink-0"
        />
      </div>
    );
};

export default ChatProductMessage;
