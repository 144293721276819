import { InboxType } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import useChatsStore from "state/chatsState";

const useChatItem = (id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();
  const { removeChat, addChat, primaryChats, requestChats } = useChatsStore();

  const fetchChat = async () => await maloumClient.chats.getChat(id);

  const moveChatToPrimary = async () => {
    if (chat?.inbox !== InboxType.REQUEST) return;
    await maloumClient.chats.update(id, { inbox: InboxType.PRIMARY });
    //ToDo: find better solution for unreadMessages
    chat.unreadMessages = false;
    chat.inbox = InboxType.PRIMARY;
    refetchChat();
    if (requestChats.find((requestChat) => requestChat._id === id)) {
      removeChat(chat._id, InboxType.REQUEST);
      addChat(chat, InboxType.PRIMARY);
    }
    queryClient.removeQueries({ queryKey: "fetch-chats" });
  };

  const moveChatToRequest = async () => {
    if (chat?.inbox !== InboxType.PRIMARY) return;
    await maloumClient.chats.update(id, { inbox: InboxType.REQUEST });
    //ToDo: find better solution for unreadMessages
    chat.unreadMessages = false;
    chat.inbox = InboxType.REQUEST;
    refetchChat();
    if (primaryChats.find((primaryChat) => primaryChat._id === id)) {
      removeChat(chat._id, InboxType.PRIMARY);
      addChat(chat, InboxType.REQUEST);
    }
    queryClient.removeQueries({ queryKey: "fetch-chats" });
  };

  // only fetch every 10 minutes max
  const {
    data: chat,
    isLoading,
    refetch: refetchChat,
    isError,
  } = useQuery(["fetch-chat", id], fetchChat, { staleTime: 10 * 60 * 10000 /* 10 minutes */ });

  return {
    chat,
    moveChatToPrimary,
    moveChatToRequest,
    isLoading,
    isError,
  };
};
export default useChatItem;
