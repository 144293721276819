import { PostDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { nFormatter } from "helper/numberHelper";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiHeart, HiOutlineChatBubbleOvalLeft, HiOutlineHeart } from "react-icons/hi2";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useUserStore from "../../state/userState";
import Pill from "../atoms/Pill";
import SubscribePopup from "../profile/SubscribePopup";

type Props = {
  post: PostDto;
  setPost: (post: PostDto) => void;
  viewedByGuest: boolean;
  className?: string;
};

const PostMetaInformation = ({ post, setPost, viewedByGuest = false, className }: Props) => {
  const user = useUserStore((state) => state.user);
  const { maloumClient } = useContext(MaloumClientContext);
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const isInReview = useMemo(() => {
    return post.reviewed === false;
  }, [post.reviewed]);

  const likePost = useCallback(async () => {
    if (!post.isVisible) {
      setShowSubscribePopup(true);
      return;
    }
    const newPostLikeState = !post.liked;

    await maloumClient.posts.like(post._id).then(() => {
      // Google Tag Manager: Like Event
      // @ts-expect-error window.dataLayer is not typed
      window.dataLayer = window.dataLayer || [];
      // @ts-expect-error window.dataLayer.push is not typed
      window.dataLayer.push({
        event: "like",
        content: {
          id: post._id,
          type: "post",
          is_paid: !post.public, // Subscription Content?
          interests: post.categories.map((category: { _id: string }) => category._id),
          creator_id: post.createdBy._id,
        },
      });

      // update local data
      setPost({ ...post, liked: newPostLikeState, likeCount: post.likeCount + (newPostLikeState ? 1 : -1) });

      if (post.createdBy._id === user._id) {
        useUserStore.setState({ user: { ...user, postLikeCount: user.postLikeCount + (newPostLikeState ? 1 : -1) } });
      }
    });
  }, [post, setPost]);

  const openComments = useCallback(() => {
    if (!post.isVisible) setShowSubscribePopup(true);
    else {
      navigate(`/post/${post._id}/comments`);
    }
  }, [post]);

  // open like page if current user is the creator and at least one like exists / opens subscribe popup if post not visible
  const openLikes = useCallback(() => {
    if (!post.isVisible) setShowSubscribePopup(true);
    else if (post.createdBy._id === user._id && post.likeCount > 0) {
      navigate(`/post/${post._id}/likes`);
    }
  }, [post]);

  const showCount = useMemo(() => post.likeCount > 0 || post.commentCount > 0, [post.likeCount, post.commentCount]);

  return (
    <>
      <div data-testid={"post-meta-info"} className={classNames("px-4 md:px-0", className)}>
        <div className={"flex select-none items-center gap-2"}>
          <div className={"cursor-pointer text-center"} onClick={likePost} data-testid={"like-button"}>
            {post.liked ? <HiHeart className={"h-6 w-8 text-red-900"} /> : <HiOutlineHeart className={"h-6 w-8"} />}
          </div>
          <div className={"cursor-pointer  text-center"} onClick={openComments}>
            <HiOutlineChatBubbleOvalLeft className={"h-6 w-8"} />
          </div>
          {isInReview && <Pill className="notranslate">{t("content.inReview")}</Pill>}
        </div>
        {showCount && (
          <div className="mt-1 flex gap-2">
            <button
              className="text-sm text-gray-700"
              data-testid={"post-goto-likes-button"}
              onClick={openLikes}
              disabled={post.createdBy._id !== user?._id}
            >
              {t(post.likeCount === 1 ? "postMeta.like" : "postMeta.likes", {
                number: nFormatter(post.likeCount, 1),
              })}
            </button>
            <button className="text-sm text-gray-700" onClick={openComments}>
              {t(post.commentCount === 1 ? "postMeta.comment" : "postMeta.comments", {
                number: nFormatter(post.commentCount, 1),
              })}
            </button>
          </div>
        )}
      </div>
      {!viewedByGuest && !post.public && (
        <SubscribePopup creator={post.createdBy} open={showSubscribePopup} setOpen={setShowSubscribePopup} />
      )}
    </>
  );
};

export default PostMetaInformation;
