import Button from "components/basics/Button";
import PopupBottom from "components/molecules/PopupBottom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "./DateTimePicker";

type Props = {
  showNow?: boolean;
  onClose: () => void;
  className?: string;
  onSave: (date: Date) => void;
  date?: Date;
};

const DatePickerPopup = ({ showNow = true, onClose, onSave, date, className }: Props) => {
  const { t } = useTranslation();
  const now = new Date();

  const [dateTime, setDateTime] = useState(date ?? now);

  const onSchedule = () => {
    onSave(dateTime);
    onClose();
  };

  const handleTimeChange = (newDate: Date) => {
    setDateTime(newDate);
  };

  const onScheduleNow = () => {
    setDateTime(now);
    onSave(now);
    onClose();
  };

  return (
    <PopupBottom open={true} setOpen={onClose} className={className}>
      <div className="flex h-fit w-full items-center justify-center gap-4 overflow-hidden">
        <DateTimePicker onChange={handleTimeChange} value={dateTime} />
      </div>
      <div className="grow" />
      <div className="flex gap-2">
        {showNow && <Button variant="secondary" text={t("chats.broadcasts.sendInstantly")} onClick={onScheduleNow} />}
        <Button variant="primary" text={t("chats.broadcasts.schedule")} onClick={onSchedule} />
      </div>
    </PopupBottom>
  );
};

export default DatePickerPopup;
