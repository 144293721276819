import {
  MediaCandidateDto,
  MessageContentTemplateMediaDto,
  PictureCandidateDto,
  PrivateUserDto,
  ThumbnailCandidateDto,
  VideoCandidateDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import MultipleMediaPopup from "components/basics/MutlipleMediaPopup";
import { useMemo, useState } from "react";
import useUserStore from "state/userState";
import ChatGridImage from "./ChatGridImage";
import SingleMediaMessage from "./SingleMediaMessage";

type Props = {
  media: (PictureCandidateDto | VideoCandidateDto)[];
  thumbnails: ThumbnailCandidateDto[];
  senderId: string;
};

const MediaMessage = ({ media, thumbnails, senderId }: Props) => {
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const isOwnMessage = user._id === senderId;

  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const { thumbnailsToShow, remainingMedia } = useMemo(() => {
    let thumbnailsToShow = [] as ThumbnailCandidateDto[];
    if (thumbnails)
      if (thumbnails.length < 4) thumbnailsToShow = thumbnails.slice(0, 2);
      else thumbnailsToShow = thumbnails.slice(0, 4);
    return { thumbnailsToShow, remainingMedia: thumbnails.length - thumbnailsToShow.length };
  }, [thumbnails]);

  // return simple message if only one media
  if (media.length === 1) return <SingleMediaMessage media={media[0]} thumbnail={thumbnails[0]} senderId={senderId} />;
  else {
    const popupMedia = media.map((m, i) => ({
      type: m.type satisfies MessageContentTemplateMediaDto.type | undefined,
      url: m.url,
      thumbnailUrl: thumbnails[i].url,
      width: m.width,
      height: m.height,
      uploadStatus: m.uploadStatus satisfies MediaCandidateDto.uploadStatus,
    }));

    // return media grid if multiple media
    return (
      <>
        <div className="grid grid-cols-2 gap-1">
          {thumbnailsToShow.map((t, i) => (
            <button
              className={classNames("relative h-fit w-full overflow-hidden rounded-md", {
                "rounded-br-none": isOwnMessage && i === thumbnailsToShow.length - 1,
              })}
              onClick={() => {
                setShowFullScreen(true);
                setClickedImageIndex(i);
              }}
              key={t.url}
            >
              <ChatGridImage url={t.url} uploadStatus={t.uploadStatus} type={media[i].type as "picture" | "video"} />
              {remainingMedia !== 0 && i === thumbnailsToShow.length - 1 && (
                <div
                  className={classNames(
                    "left-0 top-0 hidden h-full w-full items-center justify-center rounded-md bg-gray-950/25 text-4xl font-semibold text-white last:absolute last:flex",
                    {
                      "rounded-br-[1.5rem]": !isOwnMessage,
                      "rounded-br-none": isOwnMessage,
                      "rounded-tr-[1.5rem]": thumbnails.length < 4,
                    },
                  )}
                >{`+${remainingMedia}`}</div>
              )}
            </button>
          ))}
        </div>
        <MultipleMediaPopup
          media={popupMedia}
          clickedIndex={clickedImageIndex}
          isOpen={showFullScreen}
          onClose={() => setShowFullScreen(false)}
        />
      </>
    );
  }
};

export default MediaMessage;
