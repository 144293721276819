import { MediaUploadStatus } from "@neolime-gmbh/api-gateway-client";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import ProfileImage, { ProfileImageWithLink } from "../basics/ProfileImage";

type Props = {
  username?: string;
  isCreator?: boolean;
  url?: string;
  uploadStatus?: MediaUploadStatus;
  userIsVerified?: boolean;
};

const ChatUserCard = ({ url, username, isCreator, uploadStatus, userIsVerified }: Props) => {
  if (!username) {
    return <div className="h-6 w-full animate-pulse rounded-md bg-gray-100" />;
  }

  return (
    <div className="flex w-full items-center justify-center gap-2">
      {isCreator && userIsVerified ? (
        <>
          <ProfileImageWithLink
            url={url}
            className={"h-6 w-6"}
            to={`/creator/${username}`}
            uploadStatus={uploadStatus}
          />
          <StatefulLink to={`/creator/${username}`} className="notranslate flex items-center gap-x-1 text-sm">
            {username} {userIsVerified && <VerifiedUserIcon className={"h-5 w-5"} />}
          </StatefulLink>
        </>
      ) : (
        <>
          <ProfileImage className={"h-6 w-6"} url={url} uploadStatus={uploadStatus} />
          <span className="notranslate">{username}</span>
        </>
      )}
    </div>
  );
};

export default ChatUserCard;
