import { create } from "zustand";

interface GuestState {
  authPopupIsShown: boolean;
  showAuthPopup: () => void;
  hideAuthPopup: () => void;
}

const useGuestStore = create<GuestState>((set) => ({
  authPopupIsShown: false,
  showAuthPopup: () => set({ authPopupIsShown: true }),
  hideAuthPopup: () => set({ authPopupIsShown: false }),
}));

export default useGuestStore;
