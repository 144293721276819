import { MediaUploadStatus } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import StatefulLink from "components/atoms/utils/StatefulLink";
import UnprocessedProfileImage from "components/molecules/image/UnprocessedProfileImage";
import { useMemo, useState } from "react";
import { HiUser } from "react-icons/hi2";

type Props = {
  url?: string;
  className?: string;
  isLoading?: boolean;
  uploadStatus?: MediaUploadStatus;
  spinnerClassName?: string;
  isCurrentUser?: boolean;
};

const ProfileImage = ({
  url = "",
  className = "w-8 h-8",
  isLoading = false,
  uploadStatus,
  spinnerClassName,
  isCurrentUser = false,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(isLoading);
  const [hasDisplayImageError, setHasDisplayImageError] = useState(false);

  const Content = useMemo(() => {
    if ((uploadStatus === MediaUploadStatus.FINISHED || !uploadStatus) && !hasDisplayImageError) {
      return (
        <>
          <div
            className={classNames("animate-pulse rounded-full bg-gray-200", className, {
              hidden: isLoaded,
              block: !isLoaded,
            })}
          />
          <img
            src={url}
            alt="Profile Image"
            className={classNames("rounded-full object-cover", className, {
              hidden: !isLoaded,
              block: isLoaded,
            })}
            onError={() => {
              setHasDisplayImageError(true);
            }}
            onLoad={() => setIsLoaded(true)}
          />
        </>
      );
    } else if (uploadStatus === MediaUploadStatus.PROCESSING && isCurrentUser) {
      return <UnprocessedProfileImage className={className} spinnerClassName={spinnerClassName} />;
    } else {
      return <DefaultProfileImage className={className} />;
    }
  }, [uploadStatus, url, isLoaded, isCurrentUser, hasDisplayImageError]);

  if (isLoading) {
    return <div className={classNames("shrink-0 animate-pulse rounded-full bg-gray-200", className)} />;
  }

  return <div className={classNames("shrink-0")}>{Content}</div>;
};

type DefaultProfileImageProps = {
  className?: string;
};

const DefaultProfileImage = ({ className }: DefaultProfileImageProps) => {
  return (
    <div className={classNames("flex items-center justify-center rounded-full bg-red-500", className)}>
      <HiUser className={"h-2/3 w-2/3 text-red-900"} />
    </div>
  );
};

const withLink =
  <P extends object>(Component: React.ComponentType<P>) =>
  ({ url, className, isLoading, to, uploadStatus, isCurrentUser, spinnerClassName }: Props & { to: string }) => {
    return (
      <StatefulLink to={to}>
        <Component {...({ url, className, isLoading, uploadStatus, isCurrentUser, spinnerClassName } as P)} />
      </StatefulLink>
    );
  };

const withButton =
  <P extends object>(Component: React.ComponentType<P>) =>
  ({
    url,
    className,
    isLoading,
    onClick,
    uploadStatus,
    isCurrentUser,
    spinnerClassName,
  }: Props & { onClick: () => void | Promise<void> }) => {
    return (
      <button onClick={onClick}>
        <Component {...({ url, className, isLoading, uploadStatus, isCurrentUser, spinnerClassName } as P)} />
      </button>
    );
  };

export default ProfileImage;
export const ProfileImageWithLink = withLink(ProfileImage);
export const ProfileImageWithButton = withButton(ProfileImage);
