import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import {
  MediaCandidateDto,
  MessageContentDto,
  MessageContentType,
  MessageDto,
  PrivateUserDto,
  TextMessageContentDto,
  UploadType,
} from "@neolime-gmbh/api-gateway-client";
import { ChatProductMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/ChatProductMessageContentDto";
import { MediaMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/MediaMessageContentDto";
import { TipMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/TipMessageContentDto";
import classNames from "classnames";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import LinkifiedText from "components/atoms/utils/LinkifiedText";
import { formatDurationInSecondsIntoHoursMinutesSeconds, generateTimeTag } from "helper/dateAndTimeHelper";
import useCurrency from "hooks/useCurrency.hook";
import useLongPress from "hooks/useLongPress";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useUserStore from "../../state/userState";
import ChatProductMessage from "./ChatProductMessage";
import EditChatProductPricePopup from "./EditChatProductPricePopup";
import MediaMessage from "./MediaMessage";
import MessageMenuPopup from "./MessageMenuPopup";
import TipMessage from "./TipMessage";

type MessageContentProps = {
  content: MessageContentDto;
  messageId: string;
  senderId: string;
  displayLinks?: boolean;
};

const MessageContent = ({ content, messageId, senderId, displayLinks }: MessageContentProps) => {
  if (content.type === "text")
    return (
      <LinkifiedText className={"notranslate"} displayAsLink={!!displayLinks}>
        {(content as TextMessageContentDto).text}
      </LinkifiedText>
    );
  else if (content.type === "tip")
    return <TipMessage price={(content as TipMessageContentDto).price} senderId={senderId} />;
  else if (content.type === "chat_product")
    return (
      <ChatProductMessage
        chatProduct={content as ChatProductMessageContentDto}
        messageId={messageId}
        senderId={senderId}
      />
    );
  else if (content.type === "media")
    return (
      <MediaMessage
        media={(content as MediaMessageContentDto).media}
        thumbnails={(content as MediaMessageContentDto).thumbnails}
        senderId={senderId}
      />
    );

  return <></>;
};

type MessageFooterProps = {
  content: TextMessageContentDto | MediaMessageContentDto | TipMessageContentDto | ChatProductMessageContentDto;
  sentAt: string;
  senderId: string;
  chatPartnerId?: string;
};

const MessageFooter = ({ content, senderId, sentAt, chatPartnerId }: MessageFooterProps) => {
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const date = generateTimeTag(new Date(sentAt), true);

  const hasBuyerPaid = useMemo(() => {
    if (content?.purchasedBy) {
      return content?.purchasedBy?.includes(chatPartnerId);
    } else if (user._id !== senderId) {
      return content.isVisible;
    }
  }, [content]);

  const purchaseStatus = useMemo(() => {
    if (content?.type === "chat_product") {
      if (!hasBuyerPaid) return `${t("chatProduct.for")} ${content.price && displayCurrency(content.price.net)}`;
      else if (senderId === user._id)
        return (
          <span>
            <Trans i18nKey="chatProduct.soldFor">
              <span className="font-semibold">sold</span> for
            </Trans>
            {content.price && displayCurrency(content.price.net)}
          </span>
        );
      else
        return (
          <span>
            <Trans i18nKey="chatProduct.boughtFor">
              <span className="font-semibold">bought</span> for
            </Trans>{" "}
            {content.price && displayCurrency(content.price.gross)}
          </span>
        );
    } else return undefined;
  }, [hasBuyerPaid, content]);

  const contentAmountText = useMemo(() => {
    if (content.type === MessageContentType.CHAT_PRODUCT) {
      if (content.media.length === 1) {
        switch (content.media[0].type) {
          case UploadType.PICTURE:
            return "1";
          case UploadType.VIDEO:
            return formatDurationInSecondsIntoHoursMinutesSeconds(content.media[0].length);
        }
      } else {
        const imagesAmount = content.media.filter((m: MediaCandidateDto) => m.type === UploadType.PICTURE).length;
        const videosAmount = content.media.filter((m: MediaCandidateDto) => m.type === UploadType.VIDEO).length;
        const imageText =
          imagesAmount > 0 &&
          (imagesAmount > 1 ? t("chatProduct.images", { amount: imagesAmount }) : t("chatProduct.image"));
        const videoText =
          videosAmount > 0 &&
          (videosAmount > 1 ? t("chatProduct.videos", { amount: videosAmount }) : t("chatProduct.video"));

        if (imagesAmount > 0 && videosAmount === 0) return imageText;
        else if (imagesAmount > 0 && videosAmount > 0) return `${imageText} ${t("chatProduct.and")} ${videoText}`;
        else return videoText;
      }
    }
    return undefined;
  }, [content]);

  return (
    <div className={classNames("mt-1 flex flex-col text-xs", { "items-end": senderId === user._id })}>
      {content.type === "chat_product" && (
        <div className="flex items-center text-gray-700">
          <ContentTypeIcon type={content?.media?.length === 1 ? content?.media?.at(0)?.type : "picture"} />
          <span className="ml-0.5">
            {contentAmountText} {purchaseStatus}
          </span>
        </div>
      )}
      <span className="text-gray-400">{date}</span>
    </div>
  );
};

type MessageProps = {
  message: MessageDto;
  chatPartnerId?: string;
  displayLinks?: boolean;
  onDelete: () => void;
  onDeleteBroadcasted: () => void;
};

const Message = ({ message, chatPartnerId, displayLinks, onDelete, onDeleteBroadcasted }: MessageProps) => {
  // long press listener
  const longPress = useLongPress(() => setInSettings(showMenuPopUp));
  // check if message is from current user
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const isOwnMessage = user._id === message.senderId;

  // menu popup open
  const [inSettings, setInSettings] = useState(false);
  // change price popup open
  const [changePricePopupOpen, setChangePricePopupOpen] = useState(false);

  // show message menu popup
  // dont show if not own message, TIP or CHAT_PRODUCT that was bought
  const showMenuPopUp =
    isOwnMessage &&
    message.content.type !== MessageContentType.TIP &&
    !(
      message.content.type === MessageContentType.CHAT_PRODUCT &&
      chatPartnerId &&
      (message.content as ChatProductMessageContentDto).purchasedBy?.includes(chatPartnerId)
    );

  // make sure body is not scrollable when popup is open
  useEffect(() => {
    if (inSettings) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [inSettings]);

  const isTextOrTip = useMemo(
    () => message.content.type === MessageContentType.TEXT || message.content.type === MessageContentType.TIP,
    [message],
  );

  return (
    <>
      {inSettings && (
        <button
          className="fixed inset-0 z-[999] select-none bg-gray-300/70 backdrop-blur-sm"
          onClickCapture={() => setInSettings(false)}
        />
      )}
      <div
        className={classNames("flex flex-col py-1", {
          "items-end": isOwnMessage,
          "relative z-[9999] cursor-pointer": inSettings,
        })}
        data-testid={"message"}
        onClick={() => setInSettings(false)}
      >
        <div
          className={classNames(
            "group relative max-w-[70%] overflow-hidden whitespace-pre-wrap break-words rounded-t-[1.5rem]",
            {
              "rounded-l-[1.5rem]": isOwnMessage,
              "rounded-tr-[1.5rem]": !isOwnMessage,
              "rounded-br-[1.5rem]":
                (message.content.type !== MessageContentType.CHAT_PRODUCT ||
                  (message.content.type !== MessageContentType.CHAT_PRODUCT && message.content.visible) ||
                  (message.content.type === MessageContentType.CHAT_PRODUCT && message.content.media?.length === 1)) &&
                !isOwnMessage,
              "w-fit px-4 py-3": message.content.type === "text" || message.content.type === "tip",
              "bg-beige-400": isOwnMessage && message.content.type === "text",
              "bg-gray-100": !isOwnMessage && message.content.type === "text",
              "bg-gray-800": message.content.type === "tip",
              "w-[70%]": message.content.type === "media" || message.content.type === "chat_product",
            },
          )}
          // @ts-ignore
          onClickCapture={(e: MouseEvent) => {
            if (inSettings) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          {...longPress}
        >
          <div className={"group flex flex-col justify-center"}>
            <MessageContent
              messageId={message._id}
              content={message.content}
              senderId={message.senderId}
              displayLinks={isOwnMessage ? user.isTrusted : displayLinks}
            />
            {showMenuPopUp && !inSettings && (
              <button
                className={classNames("absolute right-0 top-0 hidden sm:group-hover:block", {
                  "pb-6 pl-6 pr-3 pt-3 text-white": !isTextOrTip,
                  "p-3": isTextOrTip,
                })}
                style={{
                  background: isTextOrTip
                    ? "radial-gradient(43.75% 43.75% at 50% 50%, #F6F4F0 0%, rgba(246, 244, 240, 0.96) 61.98%, rgba(246, 244, 240, 0.00) 100%)"
                    : "radial-gradient(100.00% 82.11% at 73.08% 20.41%, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.06) 60.19%, rgba(255, 255, 255, 0.00) 75%)",
                }}
                // @ts-ignore
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  setInSettings(true);
                }}
              >
                <EllipsisHorizontalIcon className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>
        <MessageFooter
          content={message.content}
          senderId={message.senderId}
          sentAt={message.sentAt}
          chatPartnerId={chatPartnerId}
        />
        <MessageMenuPopup
          message={message}
          chatPartnerId={chatPartnerId}
          visible={inSettings}
          onDelete={onDelete}
          onDeleteBroadcasted={onDeleteBroadcasted}
          onEditPrice={() => setChangePricePopupOpen(true)}
        />
        <EditChatProductPricePopup
          chatProduct={"isVisible" in message.content ? message.content : undefined}
          wasBroadcasted={message.isBroadcasted}
          open={changePricePopupOpen}
          onClose={() => setChangePricePopupOpen(false)}
        />
      </div>
    </>
  );
};

export default Message;
