import useStatefulNavigate from "hooks/useStatefulNavigate";
import { ChatListDto } from "@neolime-gmbh/api-gateway-client";

const useSelectedListsOverviewNavigation = () => {
  const navigate = useStatefulNavigate();

  type NavigationState = {
    includeFromLists: ChatListDto[];
    excludeFromLists: ChatListDto[];
  };

  return ({ includeFromLists, excludeFromLists }: NavigationState) => {
    navigate("/lists/selected", { state: { include: includeFromLists, exclude: excludeFromLists } });
  };
};

export default useSelectedListsOverviewNavigation;
