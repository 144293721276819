import { MediaUploadStatus, UploadType } from "@neolime-gmbh/api-gateway-client";
import MediaPopup from "components/media/MediaPopup";
import UnprocessedMedia from "components/molecules/UnprocessedMedia";
import VideoPlayer from "components/VideoPlayer";
import { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

type MultipleMediaPopupProps = {
  media: {
    type?: UploadType;
    url?: string;
    thumbnailUrl?: string;
    width?: number;
    height?: number;
    uploadStatus?: MediaUploadStatus;
  }[];
  clickedIndex: number;
  isOpen: boolean;
  onClose: () => void;
};

const MultipleMediaPopup = ({ media, clickedIndex, isOpen, onClose }: MultipleMediaPopupProps) => {
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    setOpenIndex(clickedIndex);
  }, [isOpen]);

  return (
    <MediaPopup isOpen={isOpen} onClose={onClose} topLeftText={`${openIndex + 1}/${media.length}`}>
      <div className="h-full w-full">
        <div className="flex h-full max-h-[calc(100%-3rem)] min-h-fit items-center justify-center">
          {media[openIndex].uploadStatus === MediaUploadStatus.PROCESSING ? (
            <UnprocessedMedia isSender hideText />
          ) : (
            <>
              {media[openIndex].type === "picture" ? (
                <img src={media[openIndex].url ?? ""} className="max-h-full" alt="" />
              ) : (
                <VideoPlayer
                  url={media[openIndex].url ?? ""}
                  width={media[openIndex].width}
                  height={media[openIndex].height}
                  thumbnailUrl={media[openIndex].thumbnailUrl}
                  key={openIndex}
                />
              )}
            </>
          )}
        </div>
        <div className="flex justify-between px-4 pb-4 pt-2 sm:px-0">
          <HiChevronLeft
            className="h-6 w-6 cursor-pointer fill-white"
            onClick={() => {
              if (openIndex > 0) setOpenIndex(openIndex - 1);
            }}
          />
          <HiChevronRight
            className="h-6 w-6 cursor-pointer fill-white"
            onClick={() => {
              if (openIndex < media.length - 1) setOpenIndex(openIndex + 1);
            }}
          />
        </div>
      </div>
    </MediaPopup>
  );
};

export default MultipleMediaPopup;
