import { InboxType } from "@neolime-gmbh/api-gateway-client";
import ScheduledBroadcastMessages from "components/chats/ScheduledBroadcastMessages";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useInbox from "../../hooks/useInbox.hook";
import ChatsList from "./ChatsList";

const ChatsForCreators = () => {
  // ref for loading trigger div
  const { ref, inView } = useInView();

  // chats
  const { chats, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInbox(InboxType.PRIMARY);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) fetchNextPage();
  }, [inView, hasNextPage, isLoading, isFetchingNextPage]);

  return (
    <>
      <ScheduledBroadcastMessages />
      <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
    </>
  );
};

export default ChatsForCreators;
