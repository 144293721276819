import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Container from "components/layouts/Container";
import Header from "components/layouts/Header";
import Layout from "components/layouts/Layout";
import StickyBottomBar from "components/StickyBottomBar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ConfirmedSVG } from "svg/undraw_confirmed.svg";
import Button from "../../components/basics/Button";
import useUserStore from "../../state/userState";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const VerificationSuccess = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleOnClick = () => {
    useUserStore.setState({
      user: { ...user, isVerified: true },
    });
    navigate("/");
  };

  useEffect(() => {
    // Google Tag Manager: Verification Completed Event
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error window.dataLayer is not typed
    window.dataLayer.push({
      event: "verification_completed",
      user: {
        email: user.email,
        id: user._id,
      },
    });
  }, []);

  return (
    <Layout hasNavigation={false} hideNavigationMobile={false} hideVerificationButton hasStickyBottom>
      <Header hasBack={false} hasNavigation={false} title={t("verification.verifyAccount")} />

      <Container hasStickyBottom>
        <h1
          className={"mt-3 text-center font-serif text-3xl text-red-900 xxs:text-4xl"}
          data-testid="verificationSuccessPage"
        >
          {t("verification.verified")}
        </h1>

        <p className="mt-8 text-center">{t("verification.verifiedText")}</p>

        <div className="mt-2 flex grow items-center justify-center">
          <ConfirmedSVG className="h-fit max-h-[11.25rem] w-fit max-w-[11.25rem] md:max-h-[12.5rem] md:max-w-[12.5rem]" />
        </div>
      </Container>

      <StickyBottomBar>
        <Button text={t("verification.startUsingMaloum")} onClick={handleOnClick} />
      </StickyBottomBar>
    </Layout>
  );
};

export default VerificationSuccess;
