import { MessageContentTemplateMediaDto, UploadType } from "@neolime-gmbh/api-gateway-client";
import { ScreenType, useScreenType } from "hooks/useScreenType.hook";
import { useMemo, useState } from "react";
import classNames from "classnames";
import MultipleMediaPopup from "components/basics/MutlipleMediaPopup";
import { HiPlay } from "react-icons/hi2";

type Props = {
  media: MessageContentTemplateMediaDto[];
  className?: string;
};

const OneLineMediaGrid = ({ media, className }: Props) => {
  const screenType = useScreenType();
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const displayedMediaCount = useMemo(() => (screenType === ScreenType.Mobile ? 3 : 4), [screenType]);
  const remainingMedia = useMemo(() => media.length - displayedMediaCount, [media, screenType]);

  return (
    <>
      <div className={classNames("relative grid grid-cols-3 gap-2 md:grid-cols-4", className)}>
        {media.slice(0, displayedMediaCount).map((m, i) => (
          <button
            className="relative aspect-square h-fit w-full"
            onClick={() => {
              setShowFullScreen(true);
              setClickedImageIndex(i);
            }}
            key={m._id}
          >
            <Thumbnail url={m.thumbnailUrl} type={m.type} />
            {remainingMedia > 0 && i === displayedMediaCount - 1 && (
              <div
                className={classNames(
                  "left-0 top-0 hidden h-full w-full items-center justify-center rounded-md bg-gray-950/25 text-4xl font-semibold text-white last:absolute last:flex",
                )}
              >
                {`+${remainingMedia}`}
              </div>
            )}
          </button>
        ))}
      </div>
      <MultipleMediaPopup
        media={media}
        clickedIndex={clickedImageIndex}
        isOpen={showFullScreen}
        onClose={() => setShowFullScreen(false)}
      />
    </>
  );
};

export default OneLineMediaGrid;

type ThumbnailProps = {
  url: string;
  type: UploadType;
};

const Thumbnail = ({ url, type }: ThumbnailProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <div className="relative aspect-square w-full overflow-hidden rounded-md">
      <>
        <div
          className={classNames("h-full w-full bg-gray-200", {
            hidden: isLoaded,
          })}
        />
        {!hasError && (
          <img
            src={url}
            className={classNames("h-full w-full object-cover", { hidden: !isLoaded })}
            alt=""
            onLoad={() => setIsLoaded(true)}
            onError={() => setHasError(true)}
          />
        )}
        {type === UploadType.VIDEO && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <HiPlay className="h-12 w-12 text-white" />
          </div>
        )}
      </>
    </div>
  );
};
