import classNames from "classnames";
import { PointerEvent, useMemo, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowsPointingOut, HiPause, HiPlay } from "react-icons/hi2";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import screenfull from "screenfull";

const formatTime = (time: number | undefined) => {
  if (!time || isNaN(time as number)) return "00:00";

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  if (hours) return `${hours}:${minutes.toString().padStart(2, "0")} `;
  return `${minutes}:${seconds}`;
};

type Props = {
  url: string;
  width?: number;
  height?: number;
  thumbnailUrl?: string;
};

const VideoPlayer = ({ url, width = 0, height = 0, thumbnailUrl }: Props) => {
  const { ref: inViewRef } = useInView({
    onChange: (inView) => !inView && setVideoState({ ...videoState, playing: false }),
  });
  const videoPlayerRef = useRef<ReactPlayer>(null);

  const [count, setCount] = useState(0);
  const [startedOnce, setStartedOnce] = useState(!thumbnailUrl);
  const [videoState, setVideoState] = useState({
    playing: false,
    played: 0,
    buffer: false,
  });

  const [isDragging, setIsDragging] = useState(false);

  const currentTime = useMemo(
    () => formatTime(videoPlayerRef.current?.getCurrentTime()),
    [videoPlayerRef.current?.getCurrentTime(), count],
  );

  const duration = useMemo(
    () => formatTime(videoPlayerRef.current?.getDuration()),
    [videoPlayerRef.current?.getDuration(), count],
  );

  const handlePlayPause = () => {
    if (videoPlayerRef.current?.getInternalPlayer("hls") && !startedOnce)
      videoPlayerRef.current?.getInternalPlayer("hls").startLoad();

    if (!startedOnce) setStartedOnce(true);
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const handleOnProgress = ({ played }: { played: number }) => {
    setCount(count + 1);
    if (!isDragging) setVideoState({ ...videoState, played: played });
  };

  const handleOnBuffer = () => setVideoState({ ...videoState, buffer: true });
  const handleOnBufferEnd = async () => {
    setVideoState({ ...videoState, buffer: false });
    await new Promise((resolve) =>
      setTimeout(() => {
        setVideoState({ ...videoState, buffer: false });
        resolve(undefined);
      }, 50),
    );
  };

  const aspectRatio = useMemo(() => {
    if (!width || !height) return "auto";
    return `${width} / ${height}`;
  }, [width, height]);

  const handleClickFullscreen = () => {
    if (!videoPlayerRef.current?.getInternalPlayer()) return;

    if (videoPlayerRef.current?.getInternalPlayer().webkitEnterFullscreen)
      videoPlayerRef.current?.getInternalPlayer().webkitEnterFullscreen();
    else if (screenfull.isEnabled) screenfull.request(videoPlayerRef.current.getInternalPlayer() as HTMLElement);
  };

  const fullscreenAvailable = useMemo(
    () => screenfull.isEnabled || videoPlayerRef.current?.getInternalPlayer()?.webkitEnterFullscreen,
    [videoPlayerRef.current?.getInternalPlayer()],
  );

  return (
    <div
      className="video-player relative block max-h-full w-full max-w-full overflow-hidden"
      style={{ aspectRatio: aspectRatio }}
      ref={inViewRef}
    >
      <div className="absolute h-[calc(100%_+_1px)] max-h-full w-full">
        {!!thumbnailUrl && (
          <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full items-center justify-center">
            <div
              className={classNames("z-10 mx-auto h-full max-h-full w-full max-w-full", { "z-0 hidden": startedOnce })}
            >
              <img
                src={thumbnailUrl}
                alt=""
                style={{ aspectRatio: aspectRatio }}
                className="h-full w-full object-contain"
              />
            </div>
          </div>
        )}
        <div className="absolute bottom-0 left-0 right-0 top-0">
          <div className="absolute left-0 top-0 m-0 box-border flex h-full min-w-full shrink-0 flex-col items-stretch justify-center border-0 p-0 align-baseline font-[inherit] text-[100%]">
            <ReactPlayer
              url={url}
              config={{
                file: {
                  hlsOptions: {
                    autoStartLoad: false,
                  },
                },
              }}
              width="100%"
              height="100%"
              onEnded={() => setVideoState({ ...videoState, playing: false, played: 1 })}
              style={{ display: startedOnce ? "block" : "none" }}
              onProgress={handleOnProgress}
              onBuffer={handleOnBuffer}
              onBufferEnd={handleOnBufferEnd}
              onPlay={() => setVideoState({ ...videoState, playing: true })}
              onPause={() => setVideoState({ ...videoState, playing: false })}
              progressInterval={200}
              playing={videoState.playing && !isDragging}
              controls={false}
              playsinline={true}
              ref={videoPlayerRef}
            />
          </div>
        </div>
      </div>
      {/** Buffering */}
      {videoState.buffer && videoState.playing && (
        <div className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2">
          <AiOutlineLoading3Quarters className="h-full w-full animate-spin overflow-visible stroke-[40px]" />
        </div>
      )}
      {/** Controls */}
      {startedOnce ? (
        <div className="absolute bottom-0 w-full bg-gradient-to-t from-gray-900 to-gray-900/0 pb-1 pt-10 text-white opacity-100 duration-100 group-hover:opacity-100">
          <div className="px-4">
            <ProgressBar
              isDragging={isDragging}
              setIsDragging={setIsDragging}
              progress={videoState.played * 100}
              changeProgress={(played: number) => {
                setVideoState({ ...videoState, played });
              }}
              seekTo={() => {
                videoPlayerRef.current?.seekTo(videoState.played);
                setVideoState({ ...videoState, playing: true });
              }}
            />
          </div>
          <div className="flex w-full items-center gap-2 px-4 py-2">
            <button onClick={handlePlayPause} className="z-20">
              {videoState.playing ? <HiPause className="h-6 w-6" /> : <HiPlay className="h-6 w-6" />}
            </button>
            <div className="grow" />
            <span>{`${currentTime} / ${duration}`}</span>

            {fullscreenAvailable && (
              <button onClick={handleClickFullscreen}>
                <HiArrowsPointingOut className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <button
          className="absolute left-1/2 top-1/2 z-20 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2 p-8"
          onClick={handlePlayPause}
        >
          <HiPlay className="h-16 w-16 text-white" />
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;

type ProgressBarProps = {
  isDragging: boolean;
  setIsDragging: (value: boolean) => void;
  progress: number; // between 0 and 100
  changeProgress: (progress: number) => void; // between 0 and 1
  seekTo: () => void;
};

const ProgressBar = ({ isDragging, setIsDragging, progress, changeProgress, seekTo }: ProgressBarProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const calcPlayed = (x: number) => {
    const distance = x - (ref.current?.getBoundingClientRect().left ?? 0);
    return Math.max(0, Math.min(0.999999, distance / (ref.current?.clientWidth ?? 0)));
  };

  const onPointerDown = (e: PointerEvent<HTMLDivElement>) => {
    setIsDragging(true);
    changeProgress(calcPlayed(e.clientX));
    if (!ref.current) return;

    if (!ref.current.hasPointerCapture(e.pointerId)) ref.current.setPointerCapture(e.pointerId);
  };

  const onPointerUp = (e: PointerEvent<HTMLDivElement>) => {
    setIsDragging(false);
    if (!ref.current) return;

    if (ref.current.hasPointerCapture(e.pointerId)) ref.current.releasePointerCapture(e.pointerId);
    seekTo();
  };

  const onPointerMove = (e: PointerEvent<HTMLDivElement>) => {
    if (isDragging) changeProgress(calcPlayed(e.clientX));
  };

  return (
    <div
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      ref={ref}
      className="flex h-5 cursor-pointer select-none items-center justify-center"
    >
      <div className="relative h-1 w-full cursor-pointer select-none rounded-full bg-white/50">
        <div className="h-full rounded-full bg-red-900" style={{ width: `${progress}%` }} />
        <div
          className="absolute top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-red-900"
          style={{ left: `${progress}%` }}
        />
      </div>
    </div>
  );
};
