import { MediaUploadStatus, UploadType } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import useVideoThumbnail from "hooks/media/useThumbnail.hook";
import { TUpload } from "types/uploads.type";
import Media from "./Media";
import UploadSpinner from "./UploadSpinner";

type Props = {
  upload: TUpload;
  className?: string;
  "data-testid"?: string;
};

const UploadingMedia = ({ upload, className, "data-testid": dataTestId }: Props) => {
  const {
    thumbnail,
    size: { width: videoWidth, height: videoHeight },
  } = useVideoThumbnail(upload);

  const isUploading = upload.status === "uploading";

  return (
    <div
      className={classNames("relative h-full w-full overflow-hidden", className, {
        "bg-gray-100": isUploading,
      })}
      data-testid={dataTestId}
    >
      {isUploading && <UploadSpinner percent={Math.round((upload.bytesUploaded / upload.bytesTotal) * 100)} />}
      {!isUploading && (
        <Media
          media={{
            url: URL.createObjectURL(upload.media),
            uploadStatus: MediaUploadStatus.FINISHED,
            height: videoHeight,
            width: videoWidth,
            type: upload.type === "image" ? UploadType.PICTURE : UploadType.VIDEO,
          }}
          thumbnail={{ url: thumbnail ?? "", uploadStatus: MediaUploadStatus.FINISHED }}
        />
      )}
    </div>
  );
};
export default UploadingMedia;
