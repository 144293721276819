import { OrderStatus } from "@neolime-gmbh/api-gateway-client";
import { useTranslation } from "react-i18next";
import { HiOutlineClock, HiOutlineCube, HiOutlineTruck } from "react-icons/hi2";
import Card from "../atoms/Card";

type Props = {
  orderStatus: OrderStatus;
  className?: string;
};

const OrderStatusCard = ({ orderStatus, className }: Props) => {
  const { t } = useTranslation();
  let orderStatusText;
  let orderStatusIcon;

  switch (orderStatus) {
    case OrderStatus.SHIPPED:
      orderStatusText = t("order.fanStatus.inDelivery");
      orderStatusIcon = <HiOutlineTruck className="h-8 w-8" />;
      break;
    case OrderStatus.DONE:
      orderStatusText = t("order.fanStatus.arrived");
      orderStatusIcon = <HiOutlineCube className="h-8 w-8" />;
      break;
    case OrderStatus.PREPARING:
    default:
      orderStatusText = t("order.fanStatus.pending");
      orderStatusIcon = <HiOutlineClock className="h-8 w-8" />;
      break;
  }

  return (
    <Card className={className}>
      <span>{orderStatusText}</span>
      {orderStatusIcon}
    </Card>
  );
};

export default OrderStatusCard;
