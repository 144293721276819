import { ChatDto, InboxType, MessageDto } from "@neolime-gmbh/api-gateway-client";
import { messageToMessagePreview } from "helper/messageHelper";
import { create } from "zustand";

type TChatsStore = {
  primaryChats: ChatDto[];
  requestChats: ChatDto[];
  markChatAsRead: (chatId: string) => void;
  updateMessageForChatIfExists: (chatId: string, message: MessageDto, markRead?: boolean) => void;
  removeChat: (chatId: string, type: InboxType) => void;
  addChat: (chat: ChatDto, type: InboxType) => void;
};

const useChatsStore = create<TChatsStore>((set) => ({
  primaryChats: [] as ChatDto[],
  requestChats: [] as ChatDto[],
  markChatAsRead: (chatId) =>
    set((state) => {
      const updateUnread = (chats: ChatDto[]) =>
        chats.map((chat) => (chat._id === chatId ? { ...chat, unreadMessages: false } : chat));
      return {
        primaryChats: updateUnread(state.primaryChats),
        requestChats: updateUnread(state.requestChats),
      };
    }),
  updateMessageForChatIfExists: (chatId, message, markRead = false) =>
    set((state) => {
      const updateChat = (chats: ChatDto[]) =>
        chats.map((chat) =>
          chat._id === chatId
            ? {
                ...chat,
                messages: [messageToMessagePreview(message)],
                unreadMessages: !markRead,
              }
            : chat,
        );
      return {
        primaryChats: updateChat(state.primaryChats),
        requestChats: updateChat(state.requestChats),
      };
    }),
  removeChat: (chatId, type) =>
    set((state) => ({
      ...state,
      [mapTypeToChatStoreKey(type)]: state[mapTypeToChatStoreKey(type)].filter((c) => c._id !== chatId),
    })),
  addChat: (chat, type) =>
    set((state) => ({
      ...state,
      [mapTypeToChatStoreKey(type)]: [...state[mapTypeToChatStoreKey(type)], chat],
    })),
}));

export const mapTypeToChatStoreKey = (type: InboxType) => {
  if (type === InboxType.PRIMARY) {
    return "primaryChats";
  } else if (type === InboxType.REQUEST) {
    return "requestChats";
  } else {
    throw new Error("Invalid type");
  }
};

export default useChatsStore;
