import classNames from "classnames";
import Card from "components/atoms/Card";
import { HiOutlineCamera, HiOutlineEllipsisHorizontal } from "react-icons/hi2";
import { ReactNode, useMemo } from "react";
import { Trans } from "react-i18next";
import { MessageContentTemplateResponseDto, UploadType } from "@neolime-gmbh/api-gateway-client";
import useCurrency from "hooks/useCurrency.hook";
import CollapsableText from "components/molecules/CollapsableText";
import DateWithIcon from "components/molecules/basics/DateWithIcon";
import OneLineMediaGrid from "components/organisms/OneLineMediaGrid";

type Props = {
  date: Date;
  content?: MessageContentTemplateResponseDto;
  onOpenPopup: () => void;
  labels: ReactNode;
  className?: string;
};

const BroadcastCard = ({ date, content, onOpenPopup, labels, className }: Props) => {
  const imageCount = content?.media?.filter((m) => m.type === UploadType.PICTURE).length ?? 0;
  const videoCount = content?.media?.filter((m) => m.type === UploadType.VIDEO).length ?? 0;

  return (
    <>
      <Card className={classNames("grid grid-cols-1 gap-2", className)}>
        <div className="flex justify-between">
          <DateWithIcon date={date} />
          <button onClick={onOpenPopup}>
            <HiOutlineEllipsisHorizontal className="h-6 w-6" />
          </button>
        </div>
        {labels}
        <CollapsableText text={content?.text ?? ""} />
        {content?.media?.length && (
          <>
            <OneLineMediaGrid media={content.media} />
            <MediaSummary imageCount={imageCount} videoCount={videoCount} price={content.price} />
          </>
        )}
      </Card>
    </>
  );
};

export default BroadcastCard;

type MediaSummaryProps = {
  imageCount: number;
  videoCount: number;
  price?: number;
  className?: string;
};

const MediaSummary = ({ imageCount, videoCount, price, className }: MediaSummaryProps) => {
  const { displayCurrency } = useCurrency();

  const text = useMemo(() => {
    if (price) {
      if (imageCount > 0 && videoCount > 0) {
        return (
          <Trans
            i18nKey="chats.broadcasts.imageAndVideoSummaryWithPrice"
            values={{ imageCount, videoCount, price: displayCurrency(price) }}
          />
        );
      }
      if (imageCount > 0) {
        return (
          <Trans
            i18nKey="chats.broadcasts.imageSummaryWithPrice"
            values={{ count: imageCount, price: displayCurrency(price) }}
          />
        );
      }
      return (
        <Trans
          i18nKey="chats.broadcasts.videoSummaryWithPrice"
          values={{ count: videoCount, price: displayCurrency(price) }}
        />
      );
    }

    if (imageCount > 0 && videoCount > 0) {
      return <Trans i18nKey="chats.broadcasts.imageAndVideoSummary" values={{ imageCount, videoCount }} />;
    }
    if (imageCount > 0) {
      return <Trans i18nKey="chats.broadcasts.imageSummary" values={{ count: imageCount }} />;
    }
    return <Trans i18nKey="chats.broadcasts.videoSummary" values={{ count: videoCount }} />;
  }, [imageCount, videoCount, price]);

  return (
    <div className={classNames("flex items-center gap-1", className)}>
      <HiOutlineCamera className="h-5 w-5" />
      <div className="flex gap-1 text-sm">{text}</div>
    </div>
  );
};
