import { RadioGroup } from "@headlessui/react";
import {
  ExclusiveChatType,
  MessageSettingsDto,
  PrivateUserDto,
  UpdateChatAccessSettingsDto,
} from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import FormikPriceInput from "components/basics/from/FormikPriceInput";
import RadioOption from "components/basics/RadioOption";
import HeaderBar from "components/layouts/HeaderBar";
import ExplanationPricePopup from "components/subscription/ExplanationPricePopup";
import userContext from "contexts/UserContext";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useUserStore from "state/userState";
import * as Yup from "yup";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";

const ChatAccessPage = () => {
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const { updateProfile } = useContext(userContext);
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  const [showSetPricePopup, setShowSetPricePopup] = useState(false);

  const currentAccessType = currentUser.chatAccessSettings.exclusiveChatType;
  const currentAccessPrice = currentUser.chatAccessSettings.chatFee;

  useEffect(() => {
    if (!user.isVerified) navigate("/verification/start");
  }, [user]);

  const decimalSchema = Yup.number().when("exclusiveChatType", (exclusiveChatType: ExclusiveChatType) => {
    if (exclusiveChatType === ExclusiveChatType.SUBS_AND_BUYS) {
      return Yup.number()
        .typeError(t("validation.onlyNumbers") || "")
        .min(1, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) || "")
        .max(9999, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) || "")
        .test("is-decimal", t("validation.only2Decimals") || "", (val) => {
          if (val !== undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val.toString());
          }
          return false;
        })
        .required();
    }
    return Yup.number().optional();
  });

  const updateChatSettings = useMutation((chatSettings: UpdateChatAccessSettingsDto & MessageSettingsDto) => {
    return updateProfile({
      chatAccessSettings: {
        chatFeeNetAmount: chatSettings.chatFeeNetAmount,
        exclusiveChatType: chatSettings.exclusiveChatType,
      },
      messageSettings: {
        isBroadcastEnabled: chatSettings.isBroadcastEnabled,
      },
    });
  });

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("chats.accessSettings.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Formik
        initialValues={{
          exclusiveChatType: currentAccessType,
          chatFeeNetAmount: currentAccessPrice?.net,
          isBroadcastEnabled: user.messageSettings.isBroadcastEnabled,
        }}
        validationSchema={Yup.object().shape({
          exclusiveChatType: Yup.mixed<ExclusiveChatType>().oneOf(Object.values(ExclusiveChatType)),
          chatFeeNetAmount: decimalSchema,
          isBroadcastEnabled: Yup.bool().required(),
        })}
        onSubmit={(values) => {
          if (!user.subscriptionPrice && values.exclusiveChatType === ExclusiveChatType.SUBSCRIBERS_ONLY) {
            setShowSetPricePopup(true);
          } else {
            updateChatSettings
              .mutateAsync({
                ...values,
                chatFeeNetAmount: values.chatFeeNetAmount ? +values.chatFeeNetAmount : undefined,
              })
              .then(() => navigate(-1));
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={"flex grow flex-col text-left"}>
            <Container>
              <p className={"mt-6 text-3xl font-semibold"}>{t("chats.accessSettings.title")}</p>
              <p className={"mt-2"}>{t("chats.accessSettings.description")}</p>

              <p className={"mb-2 mt-6 font-bold"}>{t("chats.accessSettings.options.title")}</p>
              <RadioGroup
                name="exclusiveChatType"
                value={values.exclusiveChatType}
                onChange={(value: ExclusiveChatType) => setFieldValue("exclusiveChatType", value)}
                data-testid="gender-list"
              >
                <RadioOption
                  key={ExclusiveChatType.ALL}
                  value={ExclusiveChatType.ALL}
                  title={t("chats.accessSettings.options.everybody")}
                />
                <RadioOption
                  key={ExclusiveChatType.SUBSCRIBERS_ONLY}
                  value={ExclusiveChatType.SUBSCRIBERS_ONLY}
                  title={t("chats.accessSettings.options.subscribers")}
                />
                <ExplanationPricePopup setIsOpen={setShowSetPricePopup} isOpen={showSetPricePopup} />

                <RadioOption
                  key={ExclusiveChatType.SUBS_AND_BUYS}
                  value={ExclusiveChatType.SUBS_AND_BUYS}
                  title={t("chats.accessSettings.options.payment")}
                />
              </RadioGroup>

              {values.exclusiveChatType === "SUBS_AND_BUYS" && (
                <div className="mb-3">
                  <FormikPriceInput
                    data-testid={"price-input"}
                    className="mb-2"
                    submitOnEnter={false}
                    label={t("chats.accessSettings.fee") || ""}
                    name="chatFeeNetAmount"
                    placeholder={t("price") || ""}
                  />
                </div>
              )}
              <p className={"mb-2 mt-6 font-bold"}>{t("chats.accessSettings.receiveBroadcastMessages.title")}</p>
              <RadioGroup
                name="isBroadcastEnabled"
                value={values.isBroadcastEnabled}
                onChange={(value: boolean) => setFieldValue("isBroadcastEnabled", value)}
              >
                <RadioOption
                  key={ExclusiveChatType.SUBSCRIBERS_ONLY}
                  // @ts-ignore
                  value={true}
                  title={t("chats.accessSettings.receiveBroadcastMessages.yes")}
                />

                <RadioOption
                  key={ExclusiveChatType.SUBS_AND_BUYS}
                  // @ts-ignore
                  value={false}
                  title={t("chats.accessSettings.receiveBroadcastMessages.no")}
                />
              </RadioGroup>
              <div className="grow" />
              <Button className={"mb-4"} type={"submit"} text={t("save")} disabled={updateChatSettings.isLoading} />
            </Container>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default ChatAccessPage;
