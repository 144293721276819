import { OrderDto, OrderStatus } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import MyInfiniteScroll from "../layouts/MyInfiniteScroll";
import OrderSection from "./OrderSection";

type TBoughtItemData = { CURRENT: OrderDto[]; PAST: OrderDto[] };

type TData = {
  data: TBoughtItemData;
  next: string | null;
};

type TNewItems = {
  data: OrderDto[];
  next: string | null;
};

const BoughtOrders = () => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const [data, setData] = useState<TData>({
    next: null,
    data: { CURRENT: [], PAST: [] },
  });
  const loadNext = useCallback(
    (next: string | null): Promise<TNewItems> => maloumClient.orders.buyerOverview(next),
    [maloumClient],
  );
  const setNewItems = useCallback(
    (newItems: TNewItems) => {
      const currentOrders = newItems.data.filter(
        (item) => item.status !== OrderStatus.DONE && item.status !== OrderStatus.ORDERED,
      );
      const pastOrders = newItems.data.filter((item) => item.status === OrderStatus.DONE);

      setData({
        next: newItems.next,
        data: {
          CURRENT: [...(data.data?.CURRENT ?? []), ...currentOrders],
          PAST: [...(data.data?.PAST ?? []), ...pastOrders],
        },
      });
    },
    [setData, data],
  );

  // Iterate over grouped by Status Orders and return OrderSections
  // TODO: fix ts issue
  const createItemComponent = useCallback(
    (item: TBoughtItemData) =>
      Object.keys(item).map((key: "CURRENT" | "PAST") => (
        <OrderSection key={key} orders={item[key]} heading={t(`order.status.${key}`)} />
      )),
    [],
  );

  const createLoadingItemComponent = useCallback((key: number) => <OrderSection isLoading key={key} />, []);
  const NoResultsView = useCallback(() => <div className={"py-4 text-center"}>{t("order.noPurchases")}</div>, []);

  return (
    <div>
      <MyInfiniteScroll
        loadNext={loadNext}
        data={data}
        setData={setNewItems}
        createItemComponent={createItemComponent}
        createLoadingItemComponent={createLoadingItemComponent}
        NoResultsView={NoResultsView}
        loadAnyway
      />
    </div>
  );
};

export default BoughtOrders;
