import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import lodash from "lodash";
import { useEffect, useRef } from "react";
import ValidationError from "../utilities/ValidationError";
// TODO: rework
// TODO: fix ref
type TVariant = "underline" | null;

type Props = FieldHookConfig<string>& {
  label?: string | null;
  required?: boolean;
  className?: string;
  variant?: TVariant;
  rows?: number;
  maxLength?: number;
  autogrow?: boolean;
  onValueChange?: (value: string) => void;
  placeholder?: string;
};

const Textarea = ({
  label,
  required = false,
  className,
  variant = "underline",
  rows = 1,
  maxLength,
  autogrow = true,
  onValueChange,
  placeholder,
  ...props
}: Props) => {
  const [field, meta] = useField(props);
  const { value } = meta;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const styling = variant === "underline" ? "input-underline" : "input-field";
  const inputStyle = meta.touched && meta.error ? `${styling} error` : styling;

  useEffect(() => {
    if (onValueChange) {
      onValueChange(value);
    }

    const adjustTextAreaSize = () => {
      if (autogrow && textAreaRef.current) {
        textAreaRef.current.style.height = "36px";

        const scrollHeight = textAreaRef.current.scrollHeight;

        textAreaRef.current.style.height = scrollHeight + 4 + "px";
      }
    };

    const debouncedCheck = lodash.debounce(adjustTextAreaSize, 50);

    adjustTextAreaSize();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [textAreaRef, value]);

  return (
    <div className={className || "mb-5"}>
      {label && (
        <label htmlFor={props.name} className={"text-sm"}>
          {label} {required && <span className="text-red-500"> *</span>}
        </label>
      )}
      <textarea
        className={classNames(inputStyle, "max-h-[8rem] resize-none transition-none")}
        rows={rows}
        maxLength={maxLength}
        ref={textAreaRef}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {maxLength && (
        <span className={"text-xs font-light"}>
          {value?.length || 0}/{maxLength}
        </span>
      )}

      <div>
        {meta.touched && meta.error && <ValidationError className="mt-2" message={meta.error} hasBackground={false} />}
      </div>
    </div>
  );
};

export default Textarea;
