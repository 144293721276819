import { MaloumClientProvider } from "contexts/MaloumClientContext";
import { createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { guestRouter } from "router/guestRouter";
import Loading from "../pages/basics/Loading";
import { GuestProvider } from "contexts/GuestContext";
import { useFirstline } from "@first-line/firstline-react";
import { ExchangeCodeResponse } from "@first-line/firstline-spa-js";
import { usePostHog } from "posthog-js/react";

type AuthorizationContextType = {
  getAccessToken?: () => Promise<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  doRefresh?: () => Promise<ExchangeCodeResponse>;
};

const AuthorizationContext = createContext<AuthorizationContextType>({});

export default AuthorizationContext;

const queryClient = new QueryClient();

export const AuthorizationProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessToken,
    isLoading,
    isEmailVerified,
    verifyEmail,
    user,
    doRefresh,
  } = useFirstline();

  const posthog = usePostHog();

  const pathname = window.location.pathname.split("/");
  const isCreatorProfile = pathname[1] === "creator";

  if (isLoading) {
    return <Loading />;
  }

  if (isCreatorProfile && !isAuthenticated) {
    return (
      <MaloumClientProvider>
        <QueryClientProvider client={queryClient}>
          <GuestProvider>
            <RouterProvider router={guestRouter} />
          </GuestProvider>
        </QueryClientProvider>
      </MaloumClientProvider>
    );
  }

  if (!isAuthenticated) {
    const redirect = window.location.search?.split("=")?.[1];
    if (redirect === "signup") {
      loginWithRedirect({ action_hint: "signup" });
    } else {
      loginWithRedirect();
    }

    return <Loading />;
  }

  if (!isEmailVerified) {
    const initialCreator = localStorage.getItem("initialCreator");
    posthog.capture("signup-start", {
      initialCreator: initialCreator,
    });
    
    verifyEmail();
    return <Loading />;
  }

  return (
    <AuthorizationContext.Provider value={{ getAccessToken, user, doRefresh }}>
      {children}
    </AuthorizationContext.Provider>
  );
};
