import { ContentDto, ContentType, PostDto, PrivateUserDto, ProductDto } from "@neolime-gmbh/api-gateway-client";
import EmptyFeed from "components/EmptyFeed";
import Layout from "components/layouts/Layout";
import { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { ReactComponent as MaloumLogo } from "svg/logo/maloum_logo.svg";
import Container from "../components/layouts/Container";
import PostItem, { PostItemLoading } from "../components/post/PostItem";
import ProductItem from "../components/product/ProductItem";
import useFeed from "../hooks/useFeed.hook";
import useUserStore from "../state/userState";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const FeedPage = () => {
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto & { firstOpening?: boolean }>((state) => state.user);
  const { ref, inView } = useInView();
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, updatePost, removePost } = useFeed();

  const createItemComponent = useCallback(
    (item: ContentDto) => {
      // TODO: better differentiate between Post and Product
      if (item.type === ContentType.POST) {
        return (
          <PostItem
            key={item._id}
            post={item as PostDto}
            deletedCallback={() => {
              removePost(item._id);
            }}
            updateCallback={updatePost}
          />
        );
      } else if (item.type === ContentType.PHYSICAL_PRODUCT) {
        return (
          <ProductItem
            key={item._id}
            product={item as ProductDto}
            user={item.createdBy}
            deletedCallback={() => removePost(item._id)}
          />
        );
      } else return undefined;
    },
    [removePost, updatePost],
  );

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  // TODO: remove after implementing the new flow
  // redirect to verification start, if user opens app for first time
  useEffect(() => {
    if (user.firstOpening) {
      useUserStore.setState({ user: { ...user, firstOpening: false } });
      navigate("/verification/start");
    }
  }, [user]);

  return (
    <Layout hideVerificationButton>
      <Container hasPadding={false} as="main">
        <div className={"block px-5 py-4 sm:hidden"}>
          <MaloumLogo className={"h-6 fill-red-900"} />
        </div>

        <div className="relative pb-16">
          {data ? (
            <>
              {data.pages[0].data.length === 0 ? (
                <EmptyFeed />
              ) : (
                data.pages.map((page) => page.data.map((e) => createItemComponent(e)))
              )}
              <div className="absolute bottom-[40rem]" ref={ref} />
            </>
          ) : (
            <>
              {Array(2)
                .fill(null)
                .map((_, key) => {
                  return <PostItemLoading key={key} data-testid="post-item" />;
                })}
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default FeedPage;
