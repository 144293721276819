import { Tab } from "@headlessui/react";
import { InboxType } from "@neolime-gmbh/api-gateway-client";
import Callout from "components/atoms/Callout";
import UnreadMessagesBadge from "components/atoms/UnreadMessagesBadge";
import MyTab from "components/basics/MyTab";
import useUnreadRequests from "hooks/chats/useUnreadRequests.hook";
import useInbox from "hooks/useInbox.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import unreadMessagesCount from "state/unreadMessagesState";
import ChatsList from "./ChatsList";

const ChatInboxes = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const { unreadMessagesNumber } = unreadMessagesCount();
  const { unreadRequestsCount } = useUnreadRequests();

  const tabs = [InboxType.PRIMARY, InboxType.REQUEST];

  const tabName = searchParams.get("tab")?.toUpperCase() as InboxType | null;
  const isValidTab = tabName && tabs.includes(tabName);
  const selectedIndex = isValidTab ? tabs.indexOf(tabName) : 0;

  const { chats, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInbox(tabs[selectedIndex]);

  const onTabChange = (tab: number) => {
    setSearchParams({ tab: tabs[tab].toLowerCase() }, { replace: true });
  };

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isLoading, isFetchingNextPage]);

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <Tab.List className="flex justify-around">
        <MyTab>
          <TabHeader header={t("chats.primary.title")} unreadCount={unreadMessagesNumber} />
        </MyTab>
        <MyTab>
          <TabHeader header={t("chats.request.title")} unreadCount={unreadRequestsCount} />
        </MyTab>
      </Tab.List>
      <Tab.Panels className="flex flex-grow flex-col gap-2 p-4 md:px-0">
        <Tab.Panel className="flex flex-grow flex-col">
          <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
        </Tab.Panel>
        <Tab.Panel className="flex flex-grow flex-col">
          <Callout> {t("chats.request.info")}</Callout>
          <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

const TabHeader = ({ header, unreadCount }: { header: string; unreadCount: number }) => {
  return (
    <div className="flex items-center gap-1">
      <span>{header}</span>
      <UnreadMessagesBadge counter={unreadCount} />
    </div>
  );
};

export default ChatInboxes;
